<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Copyright © 2018-{{ new Date().getFullYear() }}
      <b-link
        href="https://monicagroups.com/"
        class="ml-25"
        target="_blank"
      >Monica Groups Inc.</b-link>
      <span class="d-none d-sm-inline-block">&nbsp;Tous droits réservés.</span>
    </span>

    <span v-if="canView" class="float-md-right d-none d-md-block">Signaler un problème
      <b-link
        class="ml-25"
        target="_blank"
        href="https://monicagroups.freshdesk.com/"
        >
        <feather-icon
          icon="LifeBuoyIcon"
          size="21"
          class="text-primary stroke-current"

        />
      </b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import {ref} from "@vue/composition-api";

export default {
  components: {
    BLink,
  },
  setup() {
    let storedUser = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];
    let CURRENT_ROLE = {
      displayableRole: "Invité",
      role: "public"
    }
    
    if (storedUser != null) {
      CURRENT_ROLE = storedUser.role;
    } 

    const canView = ref(Roles.roleIsAllowed(CURRENT_ROLE.role, [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role]));

    return {
      canView
    }
  }
}
</script>
